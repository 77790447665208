<template>
  <div class="onetrust">
    <span
      >Loaded ? <i>{{ status }}</i></span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'onetrust',
  setup() {
    const status = ref(false)

    onMounted(() => {
      status.value = true
    })

    return {
      status,
    }
  },
})
</script>
<style scoped>
.onetrust {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  min-height: 90vh;
  font-size: 2em;
}
</style>
